/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import { useTranslation } from 'react-i18next';
import { HubspotModal, results, UserContext } from '@mdc/ui';
import { FileResultsService, metadefenderService, Base64, localStorage, logService, InternalNavigation, i18nUtility } from '@mdc/services';
import ResultsLayout from '../../components/result/ResultsLayout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ROUTES, SCAN_FILE_STATUS, SCAN_SANITIZED_STATUS, SCAN_SANDBOX_STATUS, SCAN_INTERVAL, HUBSPOT_PORTAL_ID, HUBSPOT_FORMS, i18nConfig } from '@mdc/constants';
import Helmet from 'react-helmet';
import './Result.scss';
import useAuthRedirect from '../../components/useAuthRedirect';
import axios from 'axios';

const TYPES = ['file', 'cif', 'ip', 'domain', 'url'];
const ACTIONS = ['hash', 'regular'];

const SANDBOX_EMAIL_SENT_KEY = 'sandboxEmailSent';
const SANDBOX_COUNT_KEY = 'sandboxCount';
const SANDBOX_LAST_ID_KEY = 'sandboxLastId';
const LAST_UPLOAD_ID_KEY = 'lastUploadId';

const FS_MENU_KEYS = {
    summary: [
        'allSignalGroups', 'allTags', 'finalVerdict'
    ],
    sandboxFilesDetails: [
        'fileVisualization', 'renderedImages', 'fileSize', 'extendedData', 'metaData', 'digests'
    ],
    network: [
        'results'
    ],
    sandboxExtracted: [
        'extractedFiles', 'fileDownloadResults'
    ],
    sandboxExtractedStrings: [
        'strings'
    ],
    indicators: [
        'extractedUrls', 'extractedDomains', 'extractedEmails', 'extractedHashesMD5', 'extractedHashesSHA1', 'extractedHashesSHA256', 'extractedHashesSHA512', 'extractedUUIDs', 'extractedRegistryPathways', 'extractedRevisionSaveIDs', 'extractedIPs'
    ],
    yara: [
        'yaraMatches'
    ],
    processes: [
        'emulationData'
    ],
};

const { SandboxContext } = results;

const generateSubmenuItems = (scanResults, basePath, t, scanHistoryLength, totalAvs, location, exifResults, peInfoResults, apkInfoResults) => {
    const hasInfo = (infoType) => scanResults?.additional_info?.includes(infoType);
    const isResultEmpty = (result) => !result || Object.keys(result).length === 0;

    const conditionsToRemove = {
        exifMetadata: !hasInfo('exif') || isResultEmpty(exifResults),
        peInformation: !hasInfo('peinfo') || isResultEmpty(peInfoResults),
        apkData: !hasInfo('apk-manifest') || isResultEmpty(apkInfoResults),
        sanitization: !scanResults?.sanitized?.result || scanResults?.sanitized?.result === 'Error',
        extractedFiles: !scanResults?.extracted_files?.files_in_archive && !location?.state?.isRedirected,
        vulnerabilities: !hasInfo('vulnerability'),
        scanHistory: !scanResults?.scan_result_history_length,
        multiscan: !scanResults?.scan_results?.total_avs,
        proactiveDLP: !scanResults?.dlp_info || scanResults?.dlp_info?.errors?.scan,
    };

    const submenuItemsRaw = [
        {
            key: 'multiscan',
            'label': t('Multiscanning'),
            'to': `${basePath}/multiscan`,
            'badgeLabel': totalAvs
        },
        {
            key: 'proactiveDLP',
            'label': t('Proactive DLP'),
            'to': `${basePath}/proactive-dlp`
        },
        {
            key: 'sanitization',
            'label': t('Deep CDR'),
            'to': `${basePath}/sanitization`
        },
        {
            key: 'vulnerabilities',
            'label': t('Vulnerabilities'),
            'to': `${basePath}/vulnerabilities`
        },
        {
            key: 'extractedFiles',
            'label': t('Extracted Files'),
            'to': `${basePath}/extracted`
        },
        {
            key: 'apkData',
            'label': t('Android Metadata'),
            'to': `${basePath}/apk-data`
        },
        {
            key: 'peInformation',
            'label': t('PE Information'),
            'to': `${basePath}/peinfo`
        },
        {
            key: 'exifMetadata',
            'label': t('EXIF Metadata'),
            'to': `${basePath}/exif-metadata`
        },
        {
            key: 'scanHistory',
            'label': t('Scan History'),
            'to': `${basePath}/history`,
            'badgeLabel': scanHistoryLength || ''
        }
    ];

    const submenuItems = submenuItemsRaw.filter((item) => !conditionsToRemove[item.key]);
    submenuItems.removedItems = submenuItemsRaw.filter((item) => conditionsToRemove[item.key]);

    return submenuItems;
};

const generateDynamicAnalysisSubmenuItems = (sandboxResources, basePath, t) => {
    const keysToRemove = [];
    const fsSandboxKeys = sandboxResources ? Object.keys(sandboxResources) : null;

    if (fsSandboxKeys?.length > 0) {
        let foundKeys = ['summary'];

        Object.keys(FS_MENU_KEYS).forEach((fsKey) => {
            FS_MENU_KEYS[fsKey]?.filter((element) => {
                if (
                    fsSandboxKeys.includes(element)
                    && !foundKeys.includes(fsKey)
                    && (
                        (Array.isArray(sandboxResources[element]) && sandboxResources[element].length !== 0)
                        || (typeof sandboxResources[element] === 'object' && sandboxResources[element] !== null && Object.keys(sandboxResources[element]).length !== 0)
                    )
                ) {
                    foundKeys.push(fsKey);
                }
            });

            if (!foundKeys.includes(fsKey)) {
                keysToRemove.push(fsKey);
            }
        });

        if (fsSandboxKeys.includes('results') && Array.isArray(sandboxResources.results)) {
            let geoInfoFound = false;

            sandboxResources.results.forEach((item) => {
                if (item?.data?.geo_info) {
                    geoInfoFound = true;
                }
            });

            if (!geoInfoFound) {
                keysToRemove.push('network');
            }
        }
    }

    const submenuItemsRaw = [
        {
            key: 'summary',
            'label': t('Summary'),
            'to': `${basePath}/sandbox/summary`,
        },
        {
            key: 'sandboxFilesDetails',
            'label': t('File Details'),
            'to': `${basePath}/sandbox/details`,
        },
        {
            key: 'processes',
            'label': t('Emulation Data'),
            'to': `${basePath}/sandbox/processes`,
        },
        {
            key: 'indicators',
            'label': t('Indicators Of Compromise'),
            'to': `${basePath}/sandbox/indicators`,
        },
        {
            key: 'network',
            'label': t('Network'),
            'to': `${basePath}/sandbox/network`,
        },
        {
            key: 'sandboxExtracted',
            'label': t('Extracted Files'),
            'to': `${basePath}/sandbox/extracted`,
        },
        {
            key: 'sandboxExtractedStrings',
            'label': t('Extracted Strings'),
            'to': `${basePath}/sandbox/extracted-strings`,
        },
        {
            key: 'yara',
            'label': t('Yara Rules'),
            'to': `${basePath}/sandbox/yara`,
        },
        {
            key: 'osint',
            'label': t('OSINT Lookups'),
            'to': `${basePath}/sandbox/osint`,
        }
    ];

    const submenuItems = submenuItemsRaw.filter((menu) => {
        return !keysToRemove.includes(menu.key);
    });

    submenuItems.removedItems = submenuItemsRaw.filter((menu) => keysToRemove.includes(menu.key));

    return submenuItems;
};

const ResultsPage = ({ location, data }) => {
    const { t, ready } = useTranslation();
    const userContext = useContext(UserContext);
    const authIsLoading = userContext.state === userContext.STATES.PENDING;
    const isSignedIn = userContext.state === userContext.STATES.LOGGED_IN;
    const isScanError = useMemo(() => location?.state?.isError ? location.state : false, [location]);

    const [type, setType] = useState();
    const [id, setId] = useState();
    const [action, setAction] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [basePath, setBasePath] = useState();
    const [pathname, setPathname] = useState();
    const [pageName, setPageName] = useState('Results');

    const [isAddressResult, setIsAddressResult] = useState(false);
    useAuthRedirect();

    useEffect(() => {
        const path = location?.pathname;
        setPathname(path);

        if (!path || location?.hash) {
            return;
        }

        const split = path.split('/').slice(1);

        if (i18nConfig.whitelist.includes(split[0])) {
            // Remove language path
            split.shift();
        }

        split.shift(); // remove 'results' path
        const [kind, index, operation, actualPage] = split;

        if (!TYPES.includes(kind) || (kind === 'file' && !ACTIONS.includes(operation))) {
            typeof window !== 'undefined' && window.location.replace('/404');
        }
        setType(kind);
        setId(index);
        setAction(operation);
        setCurrentPage(actualPage || 'overview');

        const isAddressOutcome = kind === 'ip' || kind === 'url' || kind === 'domain';
        setIsAddressResult(isAddressOutcome);
        if (isAddressOutcome) {
            if (!index) {
                typeof window !== 'undefined' && window.location.replace('/404');
            } else {
                setCurrentPage(operation);
            }
        }

        let tempBasePath = undefined;

        if (isAddressOutcome && kind && index) {
            tempBasePath = `${ROUTES.results.href}/${kind}/${index}`;
        } else {
            tempBasePath = (kind && index && operation) ? `${ROUTES.results.href}/${kind}/${index}/${operation}` : undefined;
        }

        setBasePath(tempBasePath);
    }, [location]);

    const [scanning, setScanning] = useState(SCAN_FILE_STATUS.inQueue);
    const [loading, setLoading] = useState(true);

    const [progress, setProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [scanResults, setScanResults] = useState();
    const [exifResults, setExifResults] = useState();
    const [peInfoResults, setPeInfoResults] = useState();
    const [apkInfoResults, setApkInfoResults] = useState();

    // Sandbox
    const [sandboxResults, setSandboxResults] = useState(undefined);
    const [sandboxMultipleResults, setSandboxMultipleResults] = useState({});

    const [addressScanResult, setAddressScanResult] = useState(undefined);
    const [addressCategories, setAddressCategories] = useState(undefined);

    const [upVote, setUpVote] = useState(0);
    const [downVote, setDownVote] = useState(0);

    const [sanitized, setSanitized] = useState(undefined);
    const [scanError, setScanError] = useState(undefined);

    const [currentEsKey, setCurrentEsKey] = useState(null);

    const isOpswatSandboxResult = useMemo(() => (resultObject) => {
        let objectToCheck = resultObject || sandboxResults?.data;

        return Boolean(objectToCheck?.details?.Dynamic && !objectToCheck?.summary);
    }, [sandboxResults]);

    const handleScanResult = async (lastResults) => {
        try {
            // Get scan result
            const { results: outcomes, peInfo, exif, apkInfo, esKey } = await FileResultsService.lookupResults('regular', lastResults.data_id);

            if (esKey && esKey !== currentEsKey) {
                setCurrentEsKey(esKey);
            }

            if (outcomes) {
                fetchResults(outcomes, peInfo, exif, apkInfo);
            }
        } catch (e) {
            handleGetResultsError(e);
        }
    };

    const sandboxResources = useMemo(() => {
        if (!sandboxResults || sandboxResults.status !== 'done' || !sandboxResults.data || !sandboxResults.data.resources) {
            return;
        }
        const resourcesObj = Object.values(sandboxResults.data.resources).reduce((acc, objId) => {
            Object.keys(objId).forEach((item) => {
                if ((Array.isArray(objId[item]) && objId[item]?.length > 0) || (typeof objId[item] === 'object' && objId[item] !== null)) {
                    if (item === 'results') {
                        acc[item] = acc[item] ? acc[item].concat(objId[item]) : objId[item];
                    } else {
                        acc[item] = objId[item];
                    }
                } else if (!acc[item]) {
                    acc[item] = [];
                }
            });
            return acc;
        }, {});

        return resourcesObj;
    }, [sandboxResults]);

    const fetchResults = (lastResults, peInfoOutcomes = null, exifOutcomes = null, apkInfoOutcomes = null) => {
        // eslint-disable-next-line camelcase

        //lastResults?.scan_results?.hasOwnProperty('progress_percentage') for hashes from 3rd party that don't have progress_percentage
        if (!(lastResults?.scan_results?.scan_all_result_a === SCAN_FILE_STATUS.inQueue ||
            lastResults?.scan_results?.scan_all_result_a === SCAN_FILE_STATUS.inProgress ||
            (lastResults?.scan_results?.progress_percentage !== 100 &&
                lastResults?.scan_results?.hasOwnProperty('progress_percentage')))) {
            // Set scan results
            setScanResults(lastResults);
            peInfoOutcomes && setPeInfoResults(peInfoOutcomes);
            exifOutcomes && setExifResults(exifOutcomes);
            apkInfoOutcomes && setApkInfoResults(apkInfoOutcomes);

            setScanning('');

            // Set vote
            setVotes(lastResults?.votes);

            // Get sanitized results
            fetchSanitized(lastResults);
            return;
        }

        setScanning(lastResults.scan_results.scan_all_result_a);
        setProgress(lastResults.scan_results?.progress_percentage);

        if (!window) {
            return;
        }

        window.setTimeout(() => {
            (async () => {
                await handleScanResult(lastResults);
            })();
        }, SCAN_INTERVAL.file);
    };

    const fetchSanitized = (lastResults) => {
        if (lastResults.sanitized) {
            setSanitized(lastResults.sanitized);
            if (lastResults.sanitized.result === SCAN_SANITIZED_STATUS.processing && window) {
                window.setTimeout(() => {
                    (async () => {
                        try {
                            // Get scan result
                            const { results: outcomes } = await FileResultsService.lookupResults('regular', lastResults.data_id);
                            if (outcomes) {
                                fetchSanitized(outcomes);
                            }
                        } catch (e) {
                            /* eslint-disable-next-line no-console */
                            logService.error(e);
                        }
                    })();
                }, SCAN_INTERVAL.file);
            }
        } else {
            setSanitized(undefined);
        }
    };

    const [showSandboxForm, setShowSandboxForm] = useState(false);

    const handleSandboxFormClose = useCallback(() => {
        if (currentPage !== 'overview' && basePath) {
            InternalNavigation.navigation(`${basePath}/overview`, { replace: true });
        }
        setShowSandboxForm(false);
    }, [currentPage, basePath]);

    const handleSandboxFormSubmit = () => {
        setShowSandboxForm(false);
        localStorage.setItem(SANDBOX_EMAIL_SENT_KEY, true);
        const sandboxLastId = localStorage.getItem(SANDBOX_LAST_ID_KEY);
        FileResultsService.setFetchingSandboxId(sandboxLastId);
        fetchSandbox(sandboxLastId);
    };

    useEffect(() => {
        if (currentPage === 'sandbox' && sandboxResults?.needEmailSubmit && !showSandboxForm) {
            setShowSandboxForm(true);
        }
    }, [currentPage, sandboxResults]);

    const shouldShowSandboxForm = (sandboxId) => {
        const sandboxCount = localStorage.getItem(SANDBOX_COUNT_KEY) || 0;
        const sandboxEmailSent = localStorage.getItem(SANDBOX_EMAIL_SENT_KEY);
        const sandboxLastId = localStorage.getItem(SANDBOX_LAST_ID_KEY);
        if (!isSignedIn && sandboxCount > 0 && !sandboxEmailSent && sandboxLastId === sandboxId) {
            setSandboxResults({ status: SCAN_SANDBOX_STATUS.done, disabled: true, needEmailSubmit: true });
            setShowSandboxForm(true);
            return true;
        }
        return false;
    };

    const fetchSandbox = async (sandboxId) => {
        if (shouldShowSandboxForm(sandboxId) || FileResultsService.fetchingSandboxId !== sandboxId) {
            return;
        }

        try {
            const response = await metadefenderService.sandbox.getLookup({ id: sandboxId }, { 'newsandbox': 1 })[0];

            setSandboxResults({
                status: SCAN_SANDBOX_STATUS.processing,
                progress: response?.data?.scan_results?.progress_percentage?.toString(),
                progressStatus: response?.data?.scan_results?.progress_status?.toString()
            });


            if (response?.data?.scan_results?.scan_all_result_a === 'Aborted') {
                setSandboxResults({ status: SCAN_SANDBOX_STATUS.done, results: 'Aborted' });
                FileResultsService.setFetchingSandboxId(undefined);
                return;
            }

            const responseData = await axios.get(response.data.store_at);
            const reportId = responseData?.data?.reports && Object.keys(responseData?.data?.reports)[0];

            setSandboxResults({
                status: SCAN_SANDBOX_STATUS.done,
                results: {
                    score: response.data.final_verdict?.threatLevel * 100,
                    infected: response?.data.final_verdict?.verdict
                },
                data: responseData?.data?.reports[reportId]
            });
        } catch (error) {
            if (window) {
                window.setTimeout(() => {
                    fetchSandbox(sandboxId);
                }, SCAN_INTERVAL.sandbox);
            }
        }
    };


    // For fresh scanned files, we should get the sha1 from sandboxResults
    const sandboxSha1 = useMemo(() => {
        if (!sandboxResults?.results || sandboxResults?.status !== SCAN_SANDBOX_STATUS.done) {
            return;
        }

        return sandboxResults?.results?.sha1;
    }, [sandboxResults]);

    useEffect(() => {
        if (!scanResults || loading || scanning) {
            resetSandboxState();
            return;
        }

        buildSandboxMultipleResults(scanResults);
        handleSandboxScanning(scanResults);
    }, [scanResults, loading, scanning, sandboxSha1]);

    const resetSandboxState = () => {
        setSandboxResults(undefined);
        setSandboxMultipleResults({});
        FileResultsService.setFetchingSandboxId(undefined);
    };

    const buildSandboxMultipleResults = (scanResults) => {
        if (scanResults?.last_sandbox_id && Array.isArray(scanResults?.last_sandbox_id)) {
            const multipleResults = {};
            scanResults.last_sandbox_id.forEach((sandbox) => multipleResults[sandbox.sandbox_id] = sandbox);
            setSandboxMultipleResults(multipleResults);
        }
    };

    const handleSandboxScanning = (scanResults) => {
        const shouldLoadSandboxResult = (scanResults?.additional_info?.includes('sandbox') || sandboxSha1) && sandboxResults?.status !== SCAN_SANDBOX_STATUS.loading;

        if (!shouldLoadSandboxResult) {
            setSandboxResults({ status: SCAN_SANDBOX_STATUS.done });
            return;
        }

        const sandboxHash = determineSandboxHash(scanResults);
        initiateSandboxResultFetching(sandboxHash);
    };

    const determineSandboxHash = (scanResults) => {
        return scanResults?.additional_info?.includes('sandbox') ? scanResults?.file_info.sha1 : sandboxResults?.results?.sha1 || sandboxSha1;
    };

    const initiateSandboxResultFetching = async (sandboxHash) => {
        setSandboxResults({ status: SCAN_SANDBOX_STATUS.loading, hash: sandboxHash });
        try {
            const response = await fetchSandboxResult(sandboxHash);
            handleSandboxResponse(response);
        } catch (error) {
            setSandboxResults({ status: SCAN_SANDBOX_STATUS.done });
        }
    };

    const fetchSandboxResult = async (sandboxHash) => {
        return metadefenderService.hash.getAdditional({ hash: sandboxHash, info: 'sandbox' })[0];
    };

    const handleSandboxResponse = async (response) => {
        if (response?.data?.scan_results?.scan_all_result_a === 'Aborted') {
            setSandboxResults({ status: SCAN_SANDBOX_STATUS.done, results: response?.data?.scan_results?.scan_all_result_a });
            FileResultsService.setFetchingSandboxId(undefined);
            return;
        }

        if (response?.data?.scan_in_progress && !response?.data?.store_at) {
            FileResultsService.setFetchingSandboxId(response.data.scan_in_progress);
            fetchSandbox(response.data.scan_in_progress);
            return;
        }

        if (response?.data?.store_at) {
            const responseData = await axios.get(response.data.store_at);
            processSandboxResult(response, responseData);
        }
    };

    const processSandboxResult = (response, responseData) => {
        if (response?.data?.scan_results?.scan_all_result_a === 'Aborted') {
            setSandboxResults({
                status: SCAN_SANDBOX_STATUS.done,
                results: responseData.data.scan_results.scan_all_result_a
            });
            FileResultsService.setFetchingSandboxId(undefined);
            return;
        }

        const infectionScore = response.data.final_verdict?.threatLevel * 100;
        const infected = response.data.final_verdict?.verdict;
        const reportId = responseData?.data?.reports && Object.keys(responseData?.data?.reports)[0];

        setSandboxResults({
            status: SCAN_SANDBOX_STATUS.done,
            results: {
                score: infectionScore,
                infected: infected
            },
            data: responseData?.data?.reports?.[reportId]
        });
    };

    const handleGetResultsError = (e) => {
        /* eslint-disable-next-line no-console */
        logService.error(e);
        setScanError(e?.response || undefined);
        if (e.response?.data?.error?.messages && e.response.data.error.messages.length > 0) {
            if (e.response.data.error.code === 401007) {
                setErrorMessage(t('This analysis is private and only the file owner has access to it'));
            } else {
                setErrorMessage(e.response.data.error.messages[0]);
            }
        } else {
            setErrorMessage(t('The API is currently unreachable. Please try again later'));
        }
    };

    const setVotes = (votes) => {
        setUpVote(votes?.up);
        setDownVote(votes?.down);
    };

    const resetResults = () => {
        FileResultsService.setResultsData(undefined);
        setScanResults(undefined);
        setExifResults(undefined);
        setPeInfoResults(undefined);
        setApkInfoResults(undefined);
        setCurrentEsKey(undefined);

        setLoading(true);
        setScanning(SCAN_FILE_STATUS.inQueue);
        setProgress(0);

        setErrorMessage(undefined);

        // Sanitization
        setSanitized(undefined);

        setAddressScanResult(undefined);
        setAddressCategories(undefined);

        setVotes(undefined);
    };
    const getLatestResults = () => {
        if (authIsLoading || isAddressResult) {
            return;
        }

        // Reset to initial state if new results should be gotten
        resetResults();

        if (id && localStorage.getItem(LAST_UPLOAD_ID_KEY) !== id) {
            // Reset last uploaded file id, if it isn't getting data for that id
            localStorage.removeItem(LAST_UPLOAD_ID_KEY);
        }

        (async () => {
            // Get scan result
            let response;
            try {
                response = await FileResultsService.getResultsData(action, id);
                setScanResults(response.results);
                setExifResults(response.exif);
                setPeInfoResults(response.peInfo);
                setApkInfoResults(response.apkInfo);

            } catch (e) {
                handleGetResultsError(e);
            }

            if (response?.results?.scan_results && response?.results?.file_info) {
                FileResultsService.setFetchingDataId(response.results.data_id);
                // Check if scan in-progress
                fetchResults(response.results);

                setLoading(false);
            }
        })();
    };

    useEffect(() => getLatestResults(), [authIsLoading, action, id, type, isScanError]);

    useEffect(() => {
        if (authIsLoading || isAddressResult) {
            return;
        }
        if (window) {
            window.setTimeout(() => {
                (async () => {
                    try {
                        const { results: outcomes, exif, peInfo, apkInfo } = await FileResultsService.getResultsData();
                        outcomes && setScanResults(outcomes);
                        exif && setExifResults(exif);
                        peInfo && setPeInfoResults(peInfo);
                        apkInfo && setApkInfoResults(apkInfo);
                    } catch (e) {
                        handleGetResultsError(e);
                    }
                })();
            }, 5000);
        }
    }, [authIsLoading, type, FileResultsService.getResultsData(), isScanError]);

    useEffect(() => {
        if (authIsLoading || !isAddressResult) {
            return;
        }
        resetResults();
        (async () => {
            // Get scan result
            let response;
            try {
                response = await metadefenderService[type].getResults({ address: encodeURIComponent(Base64.decode(id)) })[0];
            } catch (e) {
                handleGetResultsError(e);
            }
            if (response?.data?.lookup_results) {
                setAddressScanResult(response.data);
                setAddressCategories(response.data?.lookup_results?.sources);
                setLoading(false);
            }
        })();
    }, [authIsLoading, id, type, isScanError]);

    const menuItems = useMemo(() => {
        if (!basePath) {
            return [];
        }

        const keysToRemove = [];

        if (type !== 'ip') {
            keysToRemove.push('location');
        }

        if (!scanResults?.votes) {
            keysToRemove.push('community');
        }

        const scanHistoryLength = !scanning && !loading && scanResults?.scan_result_history_length;
        const totalAvs = !scanning && !loading && scanResults?.scan_results?.total_detected_avs;
        const staticAnalysisSubmenuItems = generateSubmenuItems(scanResults, basePath, t, scanHistoryLength, totalAvs, location, exifResults, peInfoResults, apkInfoResults);
        if (!staticAnalysisSubmenuItems.length) {
            keysToRemove.push('staticAnalysis');
        }

        let dynamicAnalysisSubmenuItems = generateDynamicAnalysisSubmenuItems(sandboxResources, basePath, t);
        if (!dynamicAnalysisSubmenuItems || !sandboxResults || !sandboxResults.data || sandboxResults.status !== 'done' || sandboxResults?.data?.overallState === 'failed') {
            keysToRemove.push('dynamicAnalysis');
        }

        let menuItemsRaw = !isAddressResult ?
            [
                {
                    key: 'overview',
                    'label': t('Overview'),
                    'icon': 'icon-clipboard-regular',
                    'to': `${basePath}/overview`
                },
                {
                    key: 'staticAnalysis',
                    'label': t('Static Analysis'),
                    'icon': 'icon-direction-outline',
                    'to': '#',
                    'subMenu': staticAnalysisSubmenuItems
                },
                {
                    key: 'dynamicAnalysis',
                    'label': t('Adaptive Analysis'),
                    'icon': 'icon-microchip',
                    'to': '#',
                    'subMenu': dynamicAnalysisSubmenuItems,
                },
                {
                    key: 'community',
                    'label': t('Community'),
                    'icon': 'icon-star',
                    'to': `${basePath}/community`
                }
            ] :
            [
                {
                    key: 'overview',
                    'label': t('Overview'),
                    'icon': 'icon-clipboard-regular',
                    'to': `${basePath}/overview`
                },
                {
                    key: 'location',
                    'label': t('Location'),
                    'icon': 'icon-direction-outline',
                    'to': `${basePath}/location`
                }
            ];

        const menuElements = menuItemsRaw.filter((menu) => {
            return !keysToRemove.includes(menu.key);
        });

        menuElements.removedItems = menuItemsRaw.filter((menu) => keysToRemove.includes(menu.key));

        return menuElements;

    }, [scanResults, scanning, loading, basePath, sandboxResults, sandboxMultipleResults, sandboxResources, t]);

    const hasToNavigate = (pathName) => {
        const someHelper = ({ to }) => pathName.endsWith(to);

        return !(menuItems?.some((menu) => pathName.endsWith(menu.to) || (menu?.subMenu?.some(someHelper)) || menu?.subMenu?.removedItems?.some(someHelper))
            || menuItems?.removedItems?.some(someHelper))
            || ((scanResults || addressScanResult) && !menuItems.some((menu) => pathName.endsWith(menu.to) || menu.subMenu?.some(someHelper)));
    };

    const getCurrentPageName = (pathName) => menuItems?.reduce(
        (prePageName, menu) =>
            prePageName || (pathName.endsWith(menu.to) && menu.label) ||
            menu.subMenu?.reduce((preSubPageName, subMenu) => preSubPageName || (pathName.endsWith(subMenu.to) && subMenu.label), ''),
        '') || t('Results');

    useEffect(() => {
        if (!basePath) {
            return;
        }

        const pathName = pathname || '';

        // If sandbox results has v2 data, we should redirect to main page - summary
        if (pathName?.includes('sandbox')) {
            // eslint-disable-next-line no-useless-escape
            let sandboxComponent = pathName?.match(/([^\/]+$)/)[0] || 'summary';
            sandboxComponent = (sandboxComponent === 'sandbox' || !sandboxComponent) ? 'summary' : sandboxComponent;
            const navigateUrl = `${basePath}/sandbox/${sandboxComponent}`;
            if (!pathName.endsWith(navigateUrl)) {
                InternalNavigation.navigation(navigateUrl, { replace: true });
            }

            return;
        }

        if (!authIsLoading && pathName && menuItems && basePath && (isAddressResult || [SCAN_SANDBOX_STATUS.done, SCAN_SANDBOX_STATUS.error].includes(sandboxResults?.status))) {
            if (hasToNavigate(pathName)) {
                InternalNavigation.navigation(`${basePath}/overview`, { replace: true });
            } else {
                setPageName(getCurrentPageName(pathName));
            }
        }
    }, [type, id, action, currentPage, scanResults, addressScanResult, menuItems, authIsLoading, basePath, pathname, sandboxResults, isAddressResult, t]);

    const pageTitle = useMemo(() => {
        let pageId = '';

        if (!isAddressResult && scanResults) {
            pageId = scanResults.file_info?.sha256 || scanResults.file_info?.sha1 || scanResults.file_info?.md5;
        } else if (isAddressResult && addressScanResult) {
            pageId = addressScanResult.address;
        }

        return pageId ? `${pageId} | ${pageName}` : pageName;
    }, [isAddressResult, scanResults, addressScanResult, pageName]);

    const pageDescription = useMemo(() => {
        if (isAddressResult && addressScanResult?.address && pageName) {
            return `${pageName} | ${addressScanResult.address}`;
        }

        if (!isAddressResult) {
            const fileInfo = scanResults?.file_info;
            const hashes = [fileInfo?.md5, fileInfo?.sha1, fileInfo?.sha256].filter((hashValue) => {
                return typeof hashValue === 'string';
            });

            if (!hashes.length) {
                return pageName;
            }

            return `${pageName} | ${hashes.join(', ')}`;
        }
    }, [isAddressResult, addressScanResult, pageName, scanResults]);

    const PageFragment = useMemo(() => {
        if (authIsLoading || !currentPage) {
            return <></>;
        }

        const sandboxContextValue = {
            scanResults,
            sandboxResults,
            sandboxMultipleResults,
            getLatestResults: getLatestResults,
            fileImage: data
        };

        return <SandboxContext.Provider value={sandboxContextValue}>
            <ResultsLayout
                isSignedIn={isSignedIn}
                id={id}
                fileImage={data}
                scanning={scanning}
                progress={progress}
                loading={loading}
                errorMessage={errorMessage}
                scanResults={scanResults}
                peInfoResults={peInfoResults}
                exifResults={exifResults}
                apkInfoResults={apkInfoResults}
                sandboxResults={sandboxResults}
                opswatSandbox={isOpswatSandboxResult()}
                addressScanResult={addressScanResult}
                upVotes={upVote}
                downVotes={downVote}
                sanitized={sanitized}
                setVotes={setVotes}
                pageName={currentPage}
                menuItems={menuItems}
                hideThreatBar={isAddressResult}
                addressCategories={addressCategories}
                esKey={currentEsKey}
            />
        </SandboxContext.Provider>;
    }, [
        authIsLoading,
        isSignedIn,
        id,
        currentPage,
        data,
        userContext,
        scanResults,
        peInfoResults,
        exifResults,
        apkInfoResults,
        sandboxResults,
        sandboxMultipleResults,
        addressScanResult,
        progress,
        scanning,
        sanitized,
        loading,
        errorMessage,
        upVote,
        downVote,
        menuItems,
        isAddressResult,
        // onSelectSandboxChange,
        currentEsKey,
        addressCategories,
        isOpswatSandboxResult
    ]);

    if (!ready || authIsLoading) {
        return null;
    }

    return (
        <>
            <Helmet>
                <script
                    src='https://code.jquery.com/jquery-3.7.1.min.js'
                    integrity='sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44='
                    crossOrigin='anonymous' />
            </Helmet>

            <Layout className='results-page' pageTitle={pageTitle} pageDescription={pageDescription} scanError={scanError}>
                {PageFragment}
            </Layout>

            <HubspotModal
                portalId={HUBSPOT_PORTAL_ID}
                formId={HUBSPOT_FORMS[i18nUtility.lookupLang().replace(/-/g, '_')].SANDBOX_FORM_ID}
                handleClose={handleSandboxFormClose}
                handleSubmit={handleSandboxFormSubmit}
                show={showSandboxForm}
                title={t('Subscribe form')}
            />
        </>
    );
};

ResultsPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export default ResultsPage;

export const fileImages = graphql`
    query fileImages {
        invalid: file(relativePath: {eq: "file/invalid.svg"}) {
            publicURL
        }
        available: file(relativePath: {eq: "file/available.svg"}) {
            publicURL
        }
        processing: file(relativePath: {eq: "file/processing.gif"}) {
            publicURL
        }
        wip: file(relativePath: {eq: "icons/wip.svg"}) {
            publicURL
        }
        noVotes: file(relativePath: {eq: "icons/no-votes.svg"}) {
            publicURL
        }
        noSandboxPerformed: file(relativePath: {eq: "icons/no-dynamic.svg"}) {
            publicURL
        }
    }
`;

